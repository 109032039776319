import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"text":""}},on),[_c(VIcon,{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.type === "audio" ? "mdi-microphone" : "mdi-camera")+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.type === "audio" ? _vm.$t("rec.microphones") : _vm.$t("rec.cameras"))+" ")]),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"value":_vm.selectedDevice}},_vm._l((_vm.devices),function(device){return _c(VListItem,{key:device.id,attrs:{"value":device.id},on:{"click":function($event){return _vm.selectDevice(device.id, _vm.type)}}},[(_vm.selectedDevice === device.id)?_c('span',{staticClass:"green mr-4",staticStyle:{"width":"8px","height":"8px","border-radius":"4px"}}):_vm._e(),_c(VListItemContent,[_vm._v(" "+_vm._s(device.name)+" ")])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }