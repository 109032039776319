



































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
  Device,
  getDevices,
  selectDevice,
  getInputDevice,
} from "../lib/devices";

type DeviceType = "video" | "audio";

@Component
export default class DeviceSelect extends Vue {
  @Prop({ default: "audio" }) type!: "audio" | "video";

  devices: Device[] = [];
  selectedDevice = "";

  async get() {
    this.devices = await getDevices(this.type);
    this.selectedDevice = getInputDevice(this.type);
    if (!this.selectedDevice && this.devices.length)
      this.selectedDevice = this.devices[0].id;
  }
  async selectDevice(id: string, type: DeviceType = "audio") {
    this.selectedDevice = id;
    //console.log(type, id, this.devices);
    selectDevice(id, type);
  }
  mounted() {
    this.get();
  }
}
